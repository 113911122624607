import Vue from 'vue'
import Vuex from 'vuex'

import { GET_DELIVERY_CHARGES } from './actions.types'
import { SET_DELIVERY_CHARGES, SET_USER } from './mutations.types'
import { getDeliveryCharges } from '../services/http'
import { trackDeliveryChargesView } from '../services/gtm'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    deliveryCharges: [],
    user: {}
  },
  getters: {
    getFormattedChargesList(state) {
      const list = state.deliveryCharges.reduce((list, item) => {
        list[item.zoneName] = [...(list[item.zoneName] || []), item]
        return list
      }, {})
      const preparedArray = Object.keys(list).map(key => ({
        zoneName: key,
        shiftsData: list[key]
      }))
      const index = preparedArray.findIndex(item => item.zoneName === 'Default')
      preparedArray.unshift(...preparedArray.splice(index, 1))
      return preparedArray
    }
  },
  mutations: {
    [SET_DELIVERY_CHARGES](state, data) {
      state.deliveryCharges = data
    },
    [SET_USER](state, user) {
      state.user = user
    }
  },
  actions: {
    async [GET_DELIVERY_CHARGES]({ state, getters, commit }) {
      const response = await getDeliveryCharges()
      commit(SET_DELIVERY_CHARGES, response.data.deliveryFees)
      trackDeliveryChargesView(
        state.user?.restaurant?.id,
        getters.getFormattedChargesList
      )
    }
  }
})

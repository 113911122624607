export default {
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'GBP'
    }
  },
  messages: {
    delivery_charges: 'Delivery charges',
    change_delivery_charge: 'To change your delivery areas or charges',
    send_message: 'send us a message',
    select_delivery_charge: ' and select <b>Delivery Areas & Charges.</b>',
    standard_charge: 'Standard charge',
    shift: 'Shift {number}',
    minimum_order: '{orderValue} minimum order = <b>{deliveryValue}</b>',
    delivery_charge: 'delivery',
    free: 'free',
    below_minimum: 'Below minimum = <b>{value}</b>',
    off: 'Off',
    free_delivery_over: '<b>{value}</b> and above order free delivery',
    free_delivery_over_off: 'Free delivery over = <b>Off</b>',
    back_to_top: 'Back to top'
  }
}

import { ApiClient } from '@je-pc/api'
import { SMARTGATEWAY_URL } from '../constants'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)

export const getDeliveryCharges = () => {
  return http.get(
    '/applications/partnerdeliverymanagement/{tenant}/{restaurantId}/deliveryfees'
  )
}
